import Login from "../components/Login";



function Shikshalokam({type, variant}) {


	return (
		<Login type={type || "shikshalokam"} variant={variant || ""}/>
	);
}

export default Shikshalokam;
