import { lang_codes } from "./configure";

export const districtLabelArray = {
   Nagaland: [ 'MON', 'DIMAPUR', 'KIPHIRE', 'KOHIMA', 'LONGLENG', 'MOKOKCHUNG', 'PEREN', 'PHEK', 'TUENSANG', 'WOKHA', 'ZUNHEBOTO' ],
   Karnataka: [ 'Bengaluru Urban South', 'Gulbarga', 'Yadgir'],
   Haryana:  [
      'Ambala', 'Bhiwani', 'Charkhi Dadri', 'Faridabad', 'Fatehbad', 'Gurugram', 
      'Hisar', 'Jhajjar', 'Jind', 'Kaithal', 'Karnal', 'Kurukshetra', 
      'Mahendragarh', 'Nuh', 'Palwal', 'Panchkula', 'Panipat', 'Rewari', 
      'Rohtak', 'Sirsa', 'Sonipat', 'Yamunanagar'
    ],
};

export const blockLabelArray = {
   Nagaland: [
      {"district": "MON", "block": "MON"},
      {"district": "MON", "block": "ABOI"},
      {"district": "MON", "block": "TIZIT"},
      {"district": "MON", "block": "TOBU"},
      {"district": "MON", "block": "WAKCHING"},
      {"district": "DIMAPUR", "block": "DHANSIRIPAR"},
      {"district": "DIMAPUR", "block": "DIMAPUR URBAN"},
      {"district": "DIMAPUR", "block": "KUSHIABILL"},
      {"district": "DIMAPUR", "block": "MEDZIPHEMA"},
      {"district": "DIMAPUR", "block": "NUILAND"},
      {"district": "KIPHIRE", "block": "KIPHIRE"},
      {"district": "KIPHIRE", "block": "PUNGRO"},
      {"district": "KIPHIRE", "block": "SEYOCHUNG"},
      {"district": "KOHIMA", "block": "CHIEPHOBOZOU"},
      {"district": "KOHIMA", "block": "L. KHEL KOHIMA"},
      {"district": "KOHIMA", "block": "SECHU ZUBZA"},
      {"district": "KOHIMA", "block": "TSEMINYU"},
      {"district": "KOHIMA", "block": "VISWEMA"},
      {"district": "LONGLENG", "block": "LONGLENG"},
      {"district": "LONGLENG", "block": "TAMLU"},
      {"district": "MOKOKCHUNG", "block": "CHANGTONGYA-B"},
      {"district": "MOKOKCHUNG", "block": "KHENSA"},
      {"district": "MOKOKCHUNG", "block": "KOBULONG"},
      {"district": "MOKOKCHUNG", "block": "MANGKOLEMBA-II"},
      {"district": "MOKOKCHUNG", "block": "MOKOKCHUNG VILLAGE"},
      {"district": "MOKOKCHUNG", "block": "WATIYIM"},
      {"district": "PEREN", "block": "AHTHIBUNG"},
      {"district": "PEREN", "block": "PEREN"},
      {"district": "PEREN", "block": "TENING"},
      {"district": "PHEK", "block": "CHOZUBA"},
      {"district": "PHEK", "block": "MELURI"},
      {"district": "PHEK", "block": "PFUTSERO"},
      {"district": "PHEK", "block": "PHEK"},
      {"district": "TUENSANG", "block": "HAKUSHANG"},
      {"district": "TUENSANG", "block": "LONGKHIM"},
      {"district": "TUENSANG", "block": "NOKLAK"},
      {"district": "TUENSANG", "block": "NOKSEN"},
      {"district": "TUENSANG", "block": "SHAMATOR"},
      {"district": "WOKHA", "block": "BHANDARI"},
      {"district": "WOKHA", "block": "SANIS"},
      {"district": "WOKHA", "block": "WOKHA"},
      {"district": "ZUNHEBOTO", "block": "AGHUNATO"},
      {"district": "ZUNHEBOTO", "block": "AKULUTO"},
      {"district": "ZUNHEBOTO", "block": "PUGHOBOTO"},
      {"district": "ZUNHEBOTO", "block": "SATAKHA"},
      {"district": "ZUNHEBOTO", "block": "ZUNHEBOTO"}
    ],
   Karnataka: [
      { "district": "Bengaluru Urban South", "block": "Anekal" },
      { "district": "Gulbarga", "block": "Afzalpur" },
      { "district": "Gulbarga", "block": "Jewargi" },
      { "district": "Gulbarga", "block": "Chincholi" },
      { "district": "Gulbarga", "block": "Chittapura" },
      { "district": "Gulbarga", "block": "Sedam" },
      { "district": "Gulbarga", "block": "Kalaburgi South" },
      { "district": "Gulbarga", "block": "Kalaburgi North" },
      { "district": "Yadgir", "block": "Surapura" },
      { "district": "Yadgir", "block": "Shahapura" },
      { "district": "Yadgir", "block": "Yadgiri" },
    ],
   Haryana: [
      {"district": "Ambala", "block": "Ambala-Ii (Cantt.)"},
      {"district": "Ambala", "block": "Barara"},
      {"district": "Ambala", "block": "Naraingarh"},
      {"district": "Ambala", "block": "Saha"},
      {"district": "Ambala", "block": "Shahzadpur"},
      {"district": "Bhiwani", "block": "Bawani Khera"},
      {"district": "Bhiwani", "block": "Behal"},
      {"district": "Bhiwani", "block": "Bhiwani"},
      {"district": "Bhiwani", "block": "Kairu"},
      {"district": "Bhiwani", "block": "Loharu"},
      {"district": "Bhiwani", "block": "Siwani"},
      {"district": "Bhiwani", "block": "Tosham"},
      {"district": "Charkhi Dadri", "block": "Badhra"},
      {"district": "Charkhi Dadri", "block": "Baund Kalan"},
      {"district": "Charkhi Dadri", "block": "Dadri"},
      {"district": "Faridabad", "block": "Ballabgarh"},
      {"district": "Faridabad", "block": "Faridabad"},
      {"district": "Fatehbad", "block": "Bhattu Kalan"},
      {"district": "Fatehbad", "block": "Bhuna"},
      {"district": "Fatehbad", "block": "Fatehabad"},
      {"district": "Fatehbad", "block": "Jakhal"},
      {"district": "Fatehbad", "block": "Ratia"},
      {"district": "Fatehbad", "block": "Tohana"},
      {"district": "Gurugram", "block": "Farrukh Nagar"},
      {"district": "Gurugram", "block": "Gurgaon"},
      {"district": "Gurugram", "block": "Pataudi"},
      {"district": "Gurugram", "block": "Sohna"},
      {"district": "Hisar", "block": "Adampur"},
      {"district": "Hisar", "block": "Agroha"},
      {"district": "Hisar", "block": "Barwala"},
      {"district": "Hisar", "block": "Hansi-I"},
      {"district": "Hisar", "block": "Hansi-Ii"},
      {"district": "Hisar", "block": "Hisar-I"},
      {"district": "Hisar", "block": "Hisar-Ii"},
      {"district": "Hisar", "block": "Narnaund"},
      {"district": "Hisar", "block": "Uklana"},
      {"district": "Jhajjar", "block": "Bahadurgarh"},
      {"district": "Jhajjar", "block": "Beri"},
      {"district": "Jhajjar", "block": "Jhajjar"},
      {"district": "Jhajjar", "block": "Matannail"},
      {"district": "Jhajjar", "block": "Salhawas"},
      {"district": "Jind", "block": "Alewa"},
      {"district": "Jind", "block": "Jind"},
      {"district": "Jind", "block": "Julana"},
      {"district": "Jind", "block": "Narwana"},
      {"district": "Jind", "block": "Pillukhera"},
      {"district": "Jind", "block": "Safidon"},
      {"district": "Jind", "block": "Uchana"},
      {"district": "Kaithal", "block": "Guhla"},
      {"district": "Kaithal", "block": "Kaithal"},
      {"district": "Kaithal", "block": "Kalayat"},
      {"district": "Kaithal", "block": "Pundri"},
      {"district": "Kaithal", "block": "Rajound"},
      {"district": "Kaithal", "block": "Siwan"},
      {"district": "Karnal", "block": "Gharaunda"},
      {"district": "Karnal", "block": "Indri"},
      {"district": "Karnal", "block": "Karnal"},
      {"district": "Karnal", "block": "Nilokheri"},
      {"district": "Karnal", "block": "Nissing"},
      {"district": "Kurukshetra", "block": "Babain"},
      {"district": "Kurukshetra", "block": "Ladwa"},
      {"district": "Kurukshetra", "block": "Pehowa"},
      {"district": "Kurukshetra", "block": "Shahbad"},
      {"district": "Kurukshetra", "block": "Thanesar"},
      {"district": "Mahendragarh", "block": "Ateli"},
      {"district": "Mahendragarh", "block": "Kanina"},
      {"district": "Mahendragarh", "block": "Mahendragarh"},
      {"district": "Mahendragarh", "block": "Nangal Chaudhry"},
      {"district": "Mahendragarh", "block": "Narnaul"},
      {"district": "Nuh", "block": "Ferozepur Jhirka"},
      {"district": "Nuh", "block": "Nagina"},
      {"district": "Nuh", "block": "Nuh"},
      {"district": "Nuh", "block": "Punahana"},
      {"district": "Nuh", "block": "Taoru"},
      {"district": "Palwal", "block": "Hassanpur"},
      {"district": "Palwal", "block": "Hathin"},
      {"district": "Palwal", "block": "Hodal"},
      {"district": "Palwal", "block": "Palwal"},
      {"district": "Panchkula", "block": "Barwala"},
      {"district": "Panchkula", "block": "Morni"},
      {"district": "Panchkula", "block": "Pinjore"},
      {"district": "Panchkula", "block": "Raipur Rani"},
      {"district": "Panipat", "block": "Bapoli"},
      {"district": "Panipat", "block": "Israna"},
      {"district": "Panipat", "block": "Madlauda"},
      {"district": "Panipat", "block": "Panipat"},
      {"district": "Panipat", "block": "Samalkha"},
      {"district": "Rewari", "block": "Bawal"},
      {"district": "Rewari", "block": "Jatusana"},
      {"district": "Rewari", "block": "Khol"},
      {"district": "Rewari", "block": "Nahar"},
      {"district": "Rewari", "block": "Rewari"},
      {"district": "Rohtak", "block": "Kalanaur"},
      {"district": "Rohtak", "block": "Lakhan Majra"},
      {"district": "Rohtak", "block": "Meham"},
      {"district": "Rohtak", "block": "Rohtak"},
      {"district": "Rohtak", "block": "Sampla"},
      {"district": "Sirsa", "block": "Baragudha"},
      {"district": "Sirsa", "block": "Dabwali"},
      {"district": "Sirsa", "block": "Ellenabad"},
      {"district": "Sirsa", "block": "Nathusari Chopta"},
      {"district": "Sirsa", "block": "Odhan"},
      {"district": "Sirsa", "block": "Rania"},
      {"district": "Sirsa", "block": "Sirsa"},
      {"district": "Sonipat", "block": "Ganaur"},
      {"district": "Sonipat", "block": "Gohana"},
      {"district": "Sonipat", "block": "Kathura"},
      {"district": "Sonipat", "block": "Kharkhoda"},
      {"district": "Sonipat", "block": "Mundlana"},
      {"district": "Sonipat", "block": "Rai"},
      {"district": "Sonipat", "block": "Sonipat"},
      {"district": "Yamunanagar", "block": "Bilaspur"},
      {"district": "Yamunanagar", "block": "Chhachhrauli"},
      {"district": "Yamunanagar", "block": "Jagadhri"},
      {"district": "Yamunanagar", "block": "Mustafabad"},
      {"district": "Yamunanagar", "block": "Radaur"},
      {"district": "Yamunanagar", "block": "Sadaura"}
   ],
};

export const stateLabelArray = [
   { state: 'Nagaland', lang_codes: lang_codes.en},
   { state: 'Karnataka', lang_codes: lang_codes.kn},
   { state: 'Haryana', lang_codes: lang_codes.en},
];

export const programLabelArray = [
   { program: 'Self Shakti Training'},
   { program: 'Spoorthi Fellowship'},
   { program: 'Buzz Green'},
   { program: 'Buzz Vyapar'},
];

export const titliStateLabelArray = [
   { state: {'Jharkhand': 'झारखंड'}, lang_codes: lang_codes.hi},
];

export const titliDistrictLabelArray = {
   Jharkhand: [ {'BOKARO': 'बोकारो'}, ],
};


export const titliBlockLabelArray = {
   Jharkhand: [
      {"district": "BOKARO", "block": {"BS City": 'बीएस सिटी'}},
      {"district": "BOKARO", "block": {"Chas Urban": 'चास अर्बन'}},
      {"district": "BOKARO", "block": {"Chandrapura": 'चंद्रपुरा'}},
      {"district": "BOKARO", "block": {"Jaredih": 'जरेडीह'}},
    ],
};